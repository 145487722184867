<template>
  <div class="history">
    <beacon-banner
      :bannerSrc="require('@/assets/images/banner_8.png')"
      title="2003年创立以来   致力于餐饮信息化和食品安全研发"
      subTitle="Food  safety  research  and  development"
    ></beacon-banner>
    <div class="title">发展历程</div>
    <div class="content">
      <ul class="history_list">
        <li
          class="history_item"
          v-for="(history, index) in historyList1"
          :key="index"
        >
          <div class="tag">
            <div class="tag_inner">
              <div class="circle"></div>
            </div>
          </div>
          <div class="history_content">
            <time class="history_time">{{ history.timeStr }}</time>
            <div class="history_title">
              {{ history.title }}
            </div>
            <div class="img_wrap">
              <img :src="history.img" alt="" />
            </div>
          </div>
        </li>
      </ul>
      <div class="v_divider">
        <img class="flag" src="@/assets/images/flag.png" alt="" />
      </div>
      <ul class="history_list">
        <li
          class="history_item"
          v-for="(history, index) in historyList2"
          :key="index"
        >
          <div class="tag">
            <div class="tag_inner">
              <div class="circle"></div>
            </div>
          </div>
          <div class="history_content">
            <time class="history_time">{{ history.timeStr }}</time>
            <div class="history_title">
              {{ history.title }}
            </div>
            <div class="img_wrap">
              <img :src="history.img" alt="" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      historyList: [
        {
          time: 2020,
          timeStr: "2020 年",
          title: "合肥市市场监督管理局来公司调研天食安监管平台",
          img: require("@/utils/images")[`history_0`],
        },
        {
          time: 2019,
          timeStr: "2019 年",
          title:
            "食品安全系统研发成功，天食安监管平台在包河区正式上线应用并应邀在长三角食品安全工作会议上作主题报告",
          img: require("@/utils/images")[`history_1`],
        },
        {
          time: 2017,
          timeStr: "2017 年",
          title: "大食慧首个百万计项目成功上线实施，同时开始食品安全平台研发",
          img: require("@/utils/images")[`history_2`],
        },
        {
          time: 2014,
          timeStr: "2014 年",
          title: "累计服务客户8000余家。自主研发大食慧智慧餐饮系列软件投入市场",
          img: require("@/utils/images")[`history_3`],
        },
        {
          time: 2003,
          timeStr: "2003 年",
          title: "成立前身安徽贝特科技发展有限公司，主营餐饮酒店行业软件",
          img: require("@/utils/images")[`history_4`],
        },
      ],
      historyList1: [],
      historyList2: [],
    };
  },
  created() {
    this.initHistoryList();
    window.addEventListener("resize", () => {
      this.initHistoryList();
    });
  },
  methods: {
    initHistoryList() {
      if (window.innerWidth <= 992) {
        this.historyList2 = this.historyList;
      } else {
        this.historyList1 = [];
        this.historyList2 = [];
        this.historyList.sort((p, n) => n.time - p.time);
        for (let i = 0; i < this.historyList.length; i++) {
          if (i % 2 === 0) {
            this.historyList1.push(this.historyList[i]);
          } else {
            this.historyList2.push(this.historyList[i]);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
  display: flex;
}

.history_list {
  width: 50%;
  padding-bottom: 77px;
  &:first-child {
    text-align: right;
    .history_item {
      .tag {
        order: 1;
        padding-right: 0;
        padding-left: 32px;
        .tag_inner {
          margin-left: 0;
          margin-right: -11px;
          .circle {
            order: 1;
          }
          &::after {
            order: 0;
          }
        }
      }
      .history_content {
        order: 0;
      }
    }
  }
  &:last-child {
    text-align: left;
    margin-top: 186px;
  }
  .history_item {
    margin-top: 13.53%;
    display: flex;
    .tag {
      flex-shrink: 0;
      width: 18.66%;
      display: flex;
      align-items: flex-start;
      padding-right: 32px;
      .circle {
        width: 19px;
        height: 19px;
        border: 2px solid #4cb034;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
          content: "";
          width: 9px;
          height: 9px;
          background: #4cb034;
          border-radius: 50%;
          display: block;
        }
      }
      .tag_inner {
        display: flex;
        flex: 1;
        align-items: center;
        margin-left: -11px;
        &::after {
          content: "";
          height: 2px;
          background: #d4ecce;
          flex: 1;
        }
      }
    }
    .history_time {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #4cb034;
    }
    .history_title {
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 28px;
      color: #333333;
      margin-top: 4.11%;
    }
  }
  .img_wrap {
    margin-top: 4.11%;
  }
}

.v_divider {
  width: 3px;
  background-color: #4cb034;
  .flag {
    width: 69px;
    height: 32px;
  }
}

@media screen and (max-width: 992px) {
  .history_list {
    padding-bottom: 0;
    &:first-child {
      display: none;
    }
    &:last-child {
      width: 100%;
      margin-top: 0;
    }
    .history_item {
      margin-top: 30vpx;
      &:first-child {
        margin-top: 60vpx;
      }
      .tag {
        width: 36vpx;
        padding-right: 10vpx;
        .circle {
          width: 12vpx;
          height: 12vpx;
          border: 1px solid #4cb034;
          &::after {
            width: 6vpx;
            height: 6vpx;
          }
        }
        .tag_inner {
          margin-left: -7vpx;
          &::after {
            height: 1px;
          }
        }
      }
      .history_time {
        font-size: 14vpx;
        text-align: left;
        display: block;
      }
      .history_title {
        margin-top: 10vpx;
        font-size: 12vpx;
        line-height: 15vpx;
      }
    }
    .img_wrap {
      margin-top: 10vpx;
    }
  }
  .v_divider {
    width: 2vpx;
    .flag {
      width: 50vpx;
      height: 22vpx;
    }
  }
}
</style>